import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Tox from '../assets/image/jpeg/toaxaward.jpg';
import { Text, Section } from "../components/Core";
import { StaticImage } from "gatsby-plugin-image"

// Styled components for custom styling
const TextContainer = styled.div`
@media screen and (min-width: 800px) {
    
 
background: #f7f7fb;  // Adjust the background color as needed
  padding-right: 80px;
  position: relative;
  z-index: 1;  // Ensure the text container is above the image
  display: inline-block;

  // Adjust position and dimensions
  &:before {
    content: '';
    position: absolute;
    top: -40px;
    left: -80px;
    right: 0px;
    bottom: -40px;
    background: #f7f7fb;
    z-index: -1;
  }
} 
`;


const HiddenText = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  margin-top: 10px;

`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    justify-content: flex-start;
 
  }

  @media screen and (max-width: 991px) {
   
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
    margin-top:20px;
  }
`;

const SecondText = styled.h3`
  font-size: 2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px !important;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    display:block
  }
`;

const CustomRow = styled(Row)`
  position: relative;
  z-index: 0;

  @media screen and (max-width: 800px) {
    
  }
`;

// Main component
const ToxaneLayout = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
    <Section py={4} className="pt-0 pb-0 mt-0 mb-0" bg="#ffffff">
    <Container className="pt-3 pb-3 "    data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
          <Row className="align-items-center d-lg-none d-xl-none">
            <Col md="9" lg="12" className="">
      
            <Iwrap>


<Itext as="h2">Prestigeous <span>Award</span></Itext>
</Iwrap>
      <SecondText>
   Clinic of excellence
      </SecondText>


            
     
            </Col>
            </ Row>
            
      <CustomRow data-aos="fade-up"
    data-aos-duration="500"
    data-aos-once="false"
    data-aos-delay="300" className="align-items-center  pt-lg-5 mt-lg-5 pb-lg-5 mb-lg-5">
          <Col className='order-lg-2 pb-4 pb-lg-0 order-lg-1' md={6}>
          <ImageContainer>
            <StaticImage
              src="../assets/image/jpeg/toaxaward.jpg"
              formats={["auto", "webp", "aif"]}
              alt="Toaxane Award"
              placeholder="blurred"
              layout='constrained'
              quality={100}
              width={624}
              className="rounded shadow"
            />
          </ImageContainer>
        </Col>
      <Col  md={6} className="d-flex justify-content-center order-lg-2">
     
          <TextContainer>
          <div className="d-none d-sm-block">
          <Iwrap>


<Itext as="h2">Prestigeous <span>Award</span></Itext>
</Iwrap>
      <SecondText>
   Clinic of excellence
      </SecondText>
      </div>
      <Text>

Dermamina has been honoured with the prestigious "Clinic of Excellence". This remarkable recognition serves as a testament to our unwavering commitment to providing exceptional services and delivering the highest standard of patient care. <br /> <br /> {' '}
     <span style={{ cursor: 'pointer', color: '#006B7B' , fontSize: '12px' }} onClick={toggleVisibility}>
          {isVisible ? 'Show less' : 'Read more'}
        </span>
      </Text>


      <HiddenText isVisible={isVisible}>
        <Text>
        We strive to create an environment where patients feel valued and supported throughout their journey. We are inspired to continue our pursuit of excellence to provide the best possible care to our valued patients. 


            </Text>
</HiddenText>

          </TextContainer>
        </Col>
      
      </CustomRow>
    </Container>
    </Section>
    </>
  );
};

export default ToxaneLayout;
